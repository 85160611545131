import Repository from "./Repository";
import { CODE_DEFINE_REPOSITORY } from "@/constants/resource.api";

export default {
    async get(codeId) {
        try {
            return await Repository.get(CODE_DEFINE_REPOSITORY, { group: codeId });
        } catch (e) {
            return this.setErrors(e);
        }
    },
    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status : false,
            errorCode : errorCode,
            data : e.response ? e.response.data : "error",
            error : e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
