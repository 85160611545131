import Repository from "./Repository";
import { ADMIN_REPOSITORY } from "../../constants";
import { CODE_IMPORT, RESULT_IMPORT, CODE } from "@/common/constants";

export default {
    async getList(data, page, limit) {
        return await Repository.post(`${ADMIN_REPOSITORY}/import-diagnostic`, {data, page, limit})
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async getCodeImport() {
        try {
            return await Repository.get(`code_define?group=${CODE_IMPORT}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getResultImport() {
        try {
            return await Repository.get(`code_define?group=${RESULT_IMPORT}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getMedicalIndication() {
        try {
            return await Repository.get(`code_define?group=${CODE}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getInsurer() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/insurer`,);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
