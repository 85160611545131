import UserRepository from "./UserRepository";
import CodeDefineRepository from "./CodeDefineRepository";
import SystemUserRepository from "./SystemUserRepository";
import CsvRepository from "./CsvRepository";
import DashboardRepository from "./DashboardRepository";
import ClientRepository from "./ClientRepository";

const repositories = {
    users : UserRepository,
    code_define: CodeDefineRepository,
    system_user: SystemUserRepository,
    csv: CsvRepository,
    dashboard: DashboardRepository,
    client: ClientRepository
};
export const RepositoryFactory = {
    get : (name) => repositories[name]
};
