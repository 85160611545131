<template>
  <div class="demo-list">
    <div class="box-search mb-3">
      <div class="row">
        <div class="col-12 position-relative">
          <div class="border rounded p-1 pr-2">
            <span class="text-left">検索条件</span>
          </div>
          <v-list class="pt-0">
            <v-list-group :value="true" class="nav-bar-group" @click="() => { this.rotateNavBar = !this.rotateNavBar }">
              <template #activator>
                <i class="fa fa-bars text-primary float-right pt-1 position-absolute" :class="[rotateNavBar ? 'nav-bar-rotate' : 'nav-bar-rollback']"></i>
              </template>
              <v-list-item>
                <div class="border border-top-0 rounded p-1 pr-2">
                  <div class="col-12 p-0 m-0 text-right">
                    <button
                            @click="clearAllSearch"
                            type="button"
                            class="btn btn-grey"
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                      クリア
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      氏名
                      <input
                              id="name"
                              name="name"
                              type="text"
                              class="form-control"
                              placeholder="氏名 or 氏名カナ"
                              v-model="dataSearch.patient_name"
                              @blur="validate('patient_name')"
                              @keyup="validate('patient_name')"
                      />
                      <span class="text-danger">{{ errors.patient_name }}</span>
                    </div>
                    <div class="col-2">
                      利用券整理番号
                      <input
                              v-model="dataSearch.patient_ticket"
                              type="text"
                              class="form-control"
                              placeholder=""
                              @blur="validate('patient_ticket')"
                              @keyup="validate('patient_ticket')"
                      />
                      <span class="text-danger">{{ errors.patient_ticket }}</span>
                    </div>
                    <div class="col-2">
                      電話番号
                      <input
                              v-model="dataSearch.patient_phone"
                              class="form-control"
                              placeholder="ハイフンなし"
                              @blur="validate('patient_phone')"
                              @keyup="validate('patient_phone')"
                      />
                      <span class="text-danger">{{ errors.patient_phone }}</span>
                    </div>
                    <div class="col-2">
                      保険者
                      <select v-model="dataSearch.insurer" class="form-select">
                        <option :value="null"></option>
                        <option
                                v-for="insurer in insurerArr"
                                :key="insurer.id + 'insurer'"
                                :value="insurer.id"
                        >
                          [{{ insurer.client_organ_code }}]
                          {{ insurer.client_organ_name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-4">
                      取込日時
                      <div class="row align-items-center">
                        <div class="col-5 mt-0 pt-0">
                          <date-picker autocomplete="off"
                                  v-model="dataSearch.from_date"
                                  id="from_date"
                                  name="from_date"
                                  :config="options"
                                  @input="validate('to_date');validate('from_date')"
                          ></date-picker>
                        </div>
                        <div class="col-1 text-center pt-0">-</div>
                        <div class="col-5 mt-0 pt-0">
                          <date-picker autocomplete="off"
                                  v-model="dataSearch.to_date"
                                  id="to_date"
                                  name="to_date"
                                  :config="options"
                                  @input="validate('to_date')"
                          ></date-picker>
                        </div>
                        <span class="text-danger">{{ errors.from_date }}</span>
                        <span class="text-danger">{{ errors.to_date }}</span>
                        <span class="text-danger">{{ In_ValRng_TermOver }}</span>
                      </div>
                    </div>
                    <div class="col-2">
                      保健指導区分
                      <select v-model="dataSearch.health_guide" class="form-select">
                        <option :value="null"></option>
                        <option
                                v-for="item in code"
                                :key="item.text + 'item'"
                                :value="item.value"
                        >
                          {{ item.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2">
                      取込区分
                      <select v-model="dataSearch.import_div" class="form-select">
                        <option :value="null"></option>
                        <option
                                v-for="code in codeImport"
                                :key="code.text + '-code-'"
                                :value="code.value"
                        >
                          {{ code.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2">
                      取込結果
                      <select v-model="dataSearch.import_result" class="form-select">
                        <option :value="null"></option>
                        <option
                                v-for="result in resultImport"
                                :key="result.text + '-result-'"
                                :value="result.value"
                        >
                          {{ result.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-6 text-right">
                      <button
                              @click="getList"
                              type="button"
                              class="btn btn-success mt-5"
                      >
                        <i class="fa fa-search" aria-hidden="true"></i>
                        検索
                      </button>
                    </div>
                  </div>
                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-9" v-cloak @drop.prevent="addDropFile" @dragover.prevent>
          <span>取込ファイル</span>
          <template>
            <v-file-input
              v-model="file"
              accept=".csv"
              color="deep-purple accent-4"
              placeholder="ファイル選択 or ここにファイルをドラッグ&ドロップ"
              :show-size="1000"
            >
            </v-file-input>
          </template>
          <span class="text-danger">{{ errorRequireImportFile }}</span>
        </div>
        <div class="col-3">
          <button
            class="btn btn-primary"
            @click="confirmUploadFile"
            :disabled="is_upload"
          >
            <i class="fas fa-upload"></i> 取込
          </button>
        </div>
      </div>
    </div>

    <div class="data-table-container">
          <v-data-table
            id="tableHighlight"
            :headers="headers"
            :items="lists"
            :fixed-header="true"
            :height="'100%'"
            disable-pagination
            hide-default-footer
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="data-table table table-responsive table-hover tableHighlight"
            >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.id + '-data-import-' + index"
                :id="'highlight' + index"
                @click="changeCellHighlight(index)"
              >
                <td class="text-nowrap">{{ item.import_row_no }}</td>
                <td class="text-nowrap">
                  <a v-if="item.id" class="text-primary" @click="userDetail(item.id)">{{
                    String(item.id).padStart(10, "0")
                  }}</a>
                </td>
                <td class="text-nowrap">{{ item.patient_name_kana }}</td>
                <td class="text-nowrap">{{ item.patient_birthday_and_sex == "null / undefined" ? "" : item.patient_birthday_and_sex }}</td>
                <td class="text-nowrap">{{ item.use_ticket_ref_number }}</td>
                <td class="text-nowrap">{{ item.health_guidance_div }}</td>
                <td class="text-nowrap">{{ item.client_organ_code_and_name == "[null] null" ? "" : item.client_organ_code_and_name }}</td>
                <td class="text-nowrap">{{ item.import_datetime }}</td>
                <td class="text-nowrap">{{ item.import_div }}</td>
                <td class="text-nowrap">{{ item.import_result }}</td>
                <td>
                    <span style="white-space: break-spaces; word-break: break-all; min-width: 800px; display: block" v-html="item.import_result_msg"></span>
                </td>
                <!-- <td class="text-nowrap" v-html="item.import_result_msg"></td> -->
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>

    <Dialog :isShow="isDialogUpload" persistent :maxWidth="600">
      <DialogImportDiagnosticData @confirmDialog="uploadFile" @closeDialog="onHandleCloseDialog"/>
    </Dialog>
    <Pagination
      :total-page="totalPage"
      :current-page="currentPage"
      @onHandleChangePage="onHandleChangePage"
    />
    <slide>
      <Detail
        v-if="component === 'Detail'"
        :checkViewOrEdit="true"
        :dataDetailId="idSelected"
        :isShowNavigation="randomString"
      ></Detail>
    </slide>
  </div>
</template>
<script>
import Slide from "@/components/Slide";
import { MESSAGE } from "@/common/constants";
import Pagination from "@/components/common/Pagination";
import ImportDiagnostic from "@/services/api/ImportDiagnostic";
import Dialog from "@/components/common/Dialog";
import DialogImportDiagnosticData from "@/components/ImportDiagnosticData/DialogImportDiagnosticData";
import { mapMutations } from "vuex";
import { SUCCESS } from "@/constants";
import Detail from "@/components/DetailImportDiagnosticData";
import moment from "moment";
import { formSearchImportDiagnostic } from "../../common/schemaValidation";
import { RepositoryFactory } from "@/services/api/RepositoryFactory";
const CsvRepository = RepositoryFactory.get("csv");
import { CODE_GENDER } from "@/common/constants";
import mixins from "@/mixins";

export default {
    name: "list",
    mixins: [mixins],
    data() {
        return {
            sortBy: "",
            sortDesc: false,
            component: "",
            headers: [
                {
                    text: "取込行No",
                    align: "start",
                    sortable: true,
                    value: "import_row_no",
                    class: "text-nowrap",
                },
                { text: "対象者ID", sortable: true, value: "id", class: "text-nowrap" },
                {
                    text: "氏名(カナ)",
                    sortable: true,
                    value: "patient_name_kana",
                    class: "text-nowrap",
                },
                {
                    text: "生年月日/性別",
                    sortable: true,
                    value: "patient_birthday_and_sex",
                    class: "text-nowrap",
                },
                {
                    text: "利用券整理番号",
                    sortable: true,
                    value: "use_ticket_ref_number",
                    class: "text-nowrap",
                },
                {
                    text: "保健指導区分",
                    sortable: true,
                    value: "health_guidance_div",
                    class: "text-nowrap",
                },
                {
                    text: "保険者",
                    sortable: true,
                    value: "client_organ_code_and_name",
                    class: "text-nowrap",
                },
                {
                    text: "取込日時",
                    sortable: true,
                    value: "import_datetime",
                    class: "text-nowrap",
                },
                {
                    text: "取込区分",
                    sortable: true,
                    value: "import_div",
                    class: "text-nowrap",
                },
                {
                    text: "取込結果",
                    sortable: true,
                    value: "import_result",
                    class: "text-nowrap",
                },
                {
                    text: "取込結果メッセージ",
                    sortable: false,
                    value: "import_result_msg",
                    class: "text-nowrap",
                },
            ],
            lists: [],
            messages: MESSAGE,
            totalPage: 1,
            currentPage: 1,
            perPage: 150,
            idSelected: null,
            dataSearch: {
                patient_name: null,
                patient_phone: null,
                patient_ticket: null,
                insurer: null,
                from_date: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
                to_date: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
                health_guide: null,
                import_result: null,
                import_div: null,
            },
            codeImport: [],
            resultImport: [],
            code: [],
            insurerArr: [],
            errors: {
                patient_name: null,
                patient_phone: null,
                patient_ticket: null,
                to_date: null,
                from_date: null
            },
            file: null,
            is_upload: false,
            code_gender: [],
            isDialogUpload: false,
            rotateNavBar: false,
            errorRequireImportFile: null,
            In_ValRng_TermOver: null,
            randomString: "",
        };
    },
    computed: {
        options() {
            return {
                format: "YYYY-MM-DD HH:mm:ss",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
    },
    components: {
        Slide,
        Detail,
        Pagination,
        Dialog,
        DialogImportDiagnosticData
    },

    async created() {
        this.code_gender = await this.getCodeDefine(CODE_GENDER, "acronym");

        ImportDiagnostic.getCodeImport().then((res) => {
            this.codeImport = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.codeImport.push({
                    text: value,
                    value: key,
                });
            }
        });
        ImportDiagnostic.getResultImport().then((res) => {
            this.resultImport = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.resultImport.push({
                    text: value,
                    value: key,
                });
            }
        });
        ImportDiagnostic.getMedicalIndication().then((res) => {
            this.code = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.code.push({
                    text: value,
                    value: key,
                });
            }
        });
        ImportDiagnostic.getInsurer().then((res) => {
            this.insurerArr = res.data.data;
        });

        $(document).keypress((e) => {
            if (e.which === 13) {
                this.getList()
            }
        });
    },

    watch: {
        "dataSearch.from_date"() {
            if (this.dataSearch.from_date && this.dataSearch.to_date && !this.errors.from_date && !this.errors.to_date) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.dataSearch.to_date).diff(moment(this.dataSearch.from_date), "days");
                if (countDay > 30) {
                    this.In_ValRng_TermOver = "取込日時は30日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver = null;
            }
        },
        "dataSearch.to_date"() {
            if (this.dataSearch.from_date && this.dataSearch.to_date && !this.errors.from_date && !this.errors.to_date) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.dataSearch.to_date).diff(moment(this.dataSearch.from_date), "days");
                if (countDay > 30) {
                    this.In_ValRng_TermOver = "取込日時は30日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver = null;
            }
        },
        "errors.from_date"() {
            if (this.dataSearch.from_date && this.dataSearch.to_date && !this.errors.from_date && !this.errors.to_date) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.dataSearch.to_date).diff(moment(this.dataSearch.from_date), "days");
                if (countDay > 30) {
                    this.In_ValRng_TermOver = "取込日時は30日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver = null;
            }
        },
        "errors.to_date"() {
            if (this.dataSearch.from_date && this.dataSearch.to_date && !this.errors.from_date && !this.errors.to_date) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.dataSearch.to_date).diff(moment(this.dataSearch.from_date), "days");
                if (countDay > 30) {
                    this.In_ValRng_TermOver = "取込日時は30日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver = null;
            }
        },
    },

    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),
        addDropFile(e) {
            this.file = e.dataTransfer.files[0]
        },

        changeCellHighlight(index) {
            $(".cellHighlight").removeClass("cellHighlight");
            $("#highlight" + index).addClass("cellHighlight");
        },
        confirmUploadFile() {
            if (!this.file){
                this.errorRequireImportFile = "取込ファイルを選択してください。";
                return;
            }
            this.errorRequireImportFile = null;
            this.isDialogUpload = true;
        },
        onHandleCloseDialog() {
            this.isDialogUpload = false;
        },
        uploadFile() {
            this.isDialogUpload = false;
            let start = moment().utcOffset("+09:00").format("YYYY-MM-DD HH:mm:ss");
            if (this.file) {
	            if (!this.file.name.endsWith(".csv")) {
		            return this.$toast.open({
			            message: "[E700]入力内容に誤りがあります。",
			            type: "error",
		            });
	            }
                this.setIsLoading(true);
                this.is_upload = true;
                let data = new FormData();
                data.append("file", this.file);
                CsvRepository.csv(data).then((res) => {
                    let end = moment().utcOffset("+09:00").format("YYYY-MM-DD HH:mm:ss");
                    this.is_upload = false;
                    this.setIsLoading(false);
                    this.file = null;
                    this.dataSearch = {
                        patient_name: null,
                        patient_phone: null,
                        patient_ticket: null,
                        insurer: null,
                        from_date: start,
                        to_date: end,
                        health_guide: null,
                        import_result: null,
                        import_div: null,
                    };

                    if (res.status) {
                        this.$toast.open({
                            message: "特定健診データの取込が完了しました。",
                            type: "success",
                        });
                    } else {
                        this.$toast.open({
                            message: "特定健診データ取込の処理中にエラーが発生しました。 データが取得できません。",
                            type: "warning",
                        });
                    }
                    this.getList();
                });
            }
        },

        openSelectFileCsv() {
            this.$refs["import-file-csv"].click();
        },
        validate(field) {
            formSearchImportDiagnostic
                .validateAt(field, this.dataSearch)
                .then(() => {
                    this.errors[field] = "";
                })
                .catch((err) => {
                    this.errors[field] = err.message;
                });
        },
        clearAllSearch() {
            this.dataSearch = {
                patient_name: null,
                patient_phone: null,
                patient_ticket: null,
                insurer: null,
                from_date: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
                to_date: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
                health_guide: null,
                import_result: null,
                import_div: null,
            };
            // this.getList();
        },
        getList() {
            formSearchImportDiagnostic
                .validate(this.dataSearch, { abortEarly: false })
                .then(() => {
                    this.errors = {
                        patient_name: null,
                        patient_phone: null,
                        patient_ticket: null,
                        to_date: null,
                        from_date: null
                    };

                    this.sortBy = "";
                    this.sortDesc = false;
                    if(!this.In_ValRng_TermOver){
                        this.setIsLoading(true);
                        this.dataSearch.from_date = moment(this.dataSearch.from_date).format(
                            "YYYY-MM-DD HH:mm:ss"
                        );
                        this.dataSearch.to_date = moment(this.dataSearch.to_date).format(
                            "YYYY-MM-DD HH:mm:ss"
                        );
                        ImportDiagnostic.getList(
                            this.dataSearch,
                            this.currentPage,
                            this.perPage
                        ).then((res) => {
                            window.scrollTo({
                                behavior: "smooth",
                                top: 0,
                            });
                            const { data, status, error } = res;

                            this.setIsLoading(false);
                            if (status !== SUCCESS) {
                                this.$toast.error(`[${error?.messageID}] ${error?.message}`);
	                            return;
                            }

                            data.data.sort(function(a,b){
                                if(a.import_datetime>b.import_datetime) return -1;
                                if(a.import_datetime<b.import_datetime) return 1;
                                if(a.import_row_no>b.import_row_no) return 1;
                                if(a.import_row_no < b.import_row_no) return -1;
                                return 0;
                            });

                            this.lists = [];
                            data.data.map((x) => {
                                this.lists.push({
                                    id: x.id,
                                    patient_name_kana: x.patient_name_kana,
                                    import_row_no: x.import_row_no,
                                    patient_birthday_and_sex:
                                      x.patient_birthday +
                                      " / " +
                                      this.code_gender[x.patient_gender],
                                    use_ticket_ref_number: x.use_ticket_ref_number,
                                    health_guidance_div: this.getHealthGuidanceDiv(
                                        x.health_guidance_div
                                    ),
                                    client_organ_code_and_name:
                                      "["+x.client_organ_code+"]" + " " + x.client_organ_name,
                                    import_datetime: x.import_datetime,
                                    import_div: this.getCodeImport(x.import_div),
                                    import_result: this.getResultImport(x.import_result),
                                    import_result_msg: x.import_result_msg,
                                });
                            });
                            $(".demo-list").closest(".container-fluid").css("height", this.lists.length ? "100%" : "auto");
                            this.totalPage = data.last_page;
                            this.currentPage = data.current_page;

                            if (this.lists.length < 1) {
                                this.notifyNoSearchResults();
                            }
                        });
                    }
                })
                .catch((err) => {
                    err.inner.forEach((error) => {
                        this.errors[error.path] = error.message;
                    });
                });
        },
        getResultImport(code){
            const value = this.resultImport.filter((x) => x.value == code);
            if (value.length > 0) {
                return value[0].text;
            }
            return null;
        },
        getCodeImport(code){
            const value = this.codeImport.filter((x) => x.value == code);
            if (value.length > 0) {
                return value[0].text;
            }
            return null;
        },
        getHealthGuidanceDiv(code) {
            const value = this.code.filter((x) => x.value == code);
            if (value.length > 0) {
                return value[0].text;
            }
            return null;
        },
        onHandleChangePage(pageNumber) {
            this.currentPage = pageNumber;
            this.getList();
        },
        userDetail(id) {
            this.idSelected = id;
            this.component = "Detail";
            this.randomString = (Math.random() + 1).toString(36).substring(7);
        },
    },
    destroyed() {
        $(document).unbind()
    }
};
</script>
<style lang="sass" scoped>
.cellHighlight
  background-color: #d2e3fb
.btn-grey
  background-color: #e0e0e0
</style>
