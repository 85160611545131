import Repository from "./Repository";

export default {
    async updateSegmentEvalByPatient(token, dataUpdate) {
        try {
            return await Repository.post(`/health-support-flow-segment-by-patient/${token}`, dataUpdate)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    
    async feedback(token, dataUpdate) {
        try {
            return await Repository.post(`/feedback/${token}`, dataUpdate)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status : false,
            errorCode : errorCode,
            data : e.response ? e.response.data : "error",
            error : e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
