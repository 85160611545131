<template>
    <v-card>
        <v-container>
            <v-card-title>
                {{ $t('common.confirm') }}
            </v-card-title>
            <div class="m-3">
                <p>{{ $t('common.n900', {value: '特定健診データ取込'}) }}</p>
            </div>
            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                <button type="button" class="btn btn-secondary" @click="$emit('closeDialog')">キャンセル</button>
                <button type="button" class="btn btn-primary" @click="$emit('confirmDialog')">OK</button>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: "DialogImportDiagnosticData",
};
</script>

<style scoped>

</style>