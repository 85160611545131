import Repository from "./Repository";
import { ADMIN_REPOSITORY } from "@/constants/resource.api";

export default {
    async create(data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/add-user`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async edit(id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/detail-user/${id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async update(data) {
        try {
            return await Repository.put(`${ADMIN_REPOSITORY}/update-user/${data.id}`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async delete(id) {
        try {
            return await Repository.delete(`${ADMIN_REPOSITORY}/delete-user/${id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async actionLock(data) {
        try {
            return await Repository.patch(`${ADMIN_REPOSITORY}/lock-user/${data.id}`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async initialPassword(data) {
        try {
            return await Repository.patch(`${ADMIN_REPOSITORY}/initial-password/${data.id}`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status : false,
            errorCode : errorCode,
            data : e.response ? e.response.data : "error",
            error : e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
