import Repository from "./Repository";
import { AUTH_REPOSITORY } from "@/constants/";

export default {
    async csv(data) {
        try {
            return await Repository.post(`${AUTH_REPOSITORY}/admin/diagnostic-data/import-csv`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status : false,
            errorCode : errorCode,
            data : e.response ? e.response.data : "error",
            error : e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
