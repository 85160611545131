import Repository from "./Repository";
import { USER_REPOSITORY } from "../../constants/";

export default {
    async get() {
        try {
            return await Repository.get(`${USER_REPOSITORY}/`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status : false,
            errorCode : errorCode,
            data : e.response ? e.response.data : "error",
            error : e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
