<template>
  <div v-if="totalPage > 1" class="container-pagination">
    <paginate
      :value="currentPage"
      :page-count="totalPage"
      :page-range="pageRange"
      :margin-pages="marginPages"
      :click-handler="pageNumber =>$emit('onHandleChangePage', pageNumber)"
      prev-text="<"
      next-text=">"
      first-last-button
      first-button-text="<<"
      last-button-text=">>"
      container-class="pagination"
      page-class="page-item"
      class="container-ul-pagination"
    >
    </paginate>
  </div>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        pageRange: {
            type: Number,
            default: 4
        },
        marginPages: {
            type: Number,
            default: 4
        },
        totalPage: {
            type: Number,
            default: 0
        },
        currentPage: {
            type: Number,
            default: 1
        }
    }
};
</script>

<style lang="sass">
  @import "../../styles/variable"
  .container-pagination
    display: flex
    justify-content: center
    align-items: center
    margin-top: 15px
    & .container-ul-pagination
      margin: 0
      padding: 20px
      text-align: center
      & li
        display: inline
        &.disabled
          background-color: $backgroundDisabledPagination
          color: $backgroundDisabledPagination
          border-radius: 50%
        &.active
            background-color: $backgroundActivePagination
            color: $white
            border-radius: 50%
        &:hover:not(.active)
          color: $black
        & a
          display: inline-block
          text-decoration: none
          padding: 10px 15px
          color: $black
        &:not(.disabled) a
          display: inline-block
          text-decoration: none
          padding: 10px 15px
          color: $black
          &:hover
            border-radius: 5px
            -webkit-transition: background-color 0.3s
            transition: background-color 0.3s
          &:hover:not(.active)
            background-color: $backgroundHoverPagination
            border-radius: 50%
</style>
